import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import { IUpdateFloorplan } from '@actions/index';
import IncompleteFloorplan from '@models/IncompleteFloorplan';
import { FormHelperText, IconButton, Input, InputAdornment, InputLabel } from '@mui/material';

export interface IProps {
  updateFloorplan: (properties: IUpdateFloorplan) => void;
  intl: IntlShape;
  onClose: () => void;
  floorplan: IncompleteFloorplan;
}

export interface IState {
  location: string;
  positionX: string;
  positionY: string;
}

const scan = () => new Promise((resolve) => {
   // @ts-ignore
  if (window.MessageInvoker) {
    // @ts-ignore
    window.MessageInvoker.postMessage('scan');
  }

   const interval = setInterval(() => {
    // @ts-ignore
    const data = window.nativeData;

    if (data) {
      // @ts-ignore
      window.nativeData = undefined;

      clearInterval(interval);

      resolve(data);
    }
  }, 200);

  setTimeout(() => {
    if (interval) {
      clearInterval(interval);
    }
  }, 120000);
});

class LocationFormDialog extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      location: '',
      positionX: '',
      positionY: '',
    };

    this.handleCancel = this.handleCancel.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleScan = this.handleScan.bind(this);
  }

  public handleChange(key: keyof IState) {
    return (e: any) => {
      const { value } = e.target;

      this.setState({
        [key]: value,
      } as Pick<IState, 'location'>);
    };
  }

  public handleScan() {
    scan().then((data: any) => {
      this.setState({ location: data });
    });
  }

  public handleCancel() {
    const { onClose } = this.props;

    onClose();

    this.setState({
      location: '',
      positionX: '',
      positionY: '',
    });
  }

  public handleSubmit() {
    const { location, positionX, positionY } = this.state;
    const { intl, floorplan } = this.props;

    const { updateFloorplan } = this.props;

    const itemLocations = floorplan.itemLocations || [];
    itemLocations.push({
      id: String(itemLocations.length + 1),
      reference: location,
      position: [Number(positionX || 0), Number(positionY || 0)],
    });

    updateFloorplan({
      intl,
      floorplanId: floorplan.id,
      properties: {
        itemLocations,
      },
      warehouseId: floorplan.warehouseId,
    });

    this.setState({
      location: '',
      positionX: '',
      positionY: '',
    });
  }

  public render() {
    const { location, positionX, positionY } = this.state;

    const buttonDisabled = location.length === 0;

    return (
      <Dialog fullWidth maxWidth="sm" open>
        <DialogTitle>
          <FormattedMessage
            id="dashboard.forms.locationform.title"
            defaultMessage="Scan Locations"
          />
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth required variant="standard">
            <InputLabel htmlFor="location">
              <FormattedMessage
                id="dashboard.forms.locationform.label_location"
                defaultMessage="Location"
              />
            </InputLabel>
            <Input
              id="location"
              value={location}
              onChange={this.handleChange('location')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={this.handleScan}
                  >
                    <QrCodeScannerIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText id="location">
              <FormattedMessage
                id="dashboard.forms.locationform.field_location_helper"
                defaultMessage="Required"
              />
            </FormHelperText>
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth required>
                <TextField
                  label={
                    <FormattedMessage
                      id="dashboard.forms.locationform.label_positionx"
                      defaultMessage="Coordinate X"
                    />
                  }
                  variant="standard"
                  value={positionX}
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={this.handleChange('positionX')}
                  helperText={
                    <FormattedMessage
                      id="dashboard.forms.locationform.field_positionx_helper"
                      defaultMessage="Required"
                    />
                  }
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth required>
                <TextField
                  label={
                    <FormattedMessage
                      id="dashboard.forms.locationform.label_positiony"
                      defaultMessage="Coordinate Y"
                    />
                  }
                  variant="standard"
                  value={positionY}
                  type="number"
                  inputProps={{ step: 0.01 }}
                  onChange={this.handleChange('positionY')}
                  helperText={
                    <FormattedMessage
                      id="dashboard.forms.locationform.field_positiony_helper"
                      defaultMessage="Required"
                    />
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.handleCancel}>
            <FormattedMessage
              id="dashboard.forms.locationform.button_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button
            onClick={this.handleSubmit}
            variant="contained"
            color="primary"
            disabled={buttonDisabled}
          >
            <FormattedMessage
              id="dashboard.forms.locationform.button_create"
              defaultMessage="Create"
            />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default injectIntl(LocationFormDialog);
