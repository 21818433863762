import Button from '@mui/material/Button';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu/Menu';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import Typography from '@mui/material/Typography/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddLocationIcon from '@mui/icons-material/AddLocation';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { Component } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import IncompleteFloorplan from '@models/IncompleteFloorplan';
import LocationForm from './LocationForm';

interface IProps {
  handleEditFloorplan: (floorplan: IncompleteFloorplan) => any;
  handleConfigureFloorplan: (floorplan: IncompleteFloorplan) => any;
  handleDeleteFloorplan: (floorplan: IncompleteFloorplan) => any;
  handleHideFloorplan: (floorplan: IncompleteFloorplan) => any;
  handleShowFloorplan: (floorplan: IncompleteFloorplan) => any;
  floorplan: IncompleteFloorplan;
  intl: IntlShape;
}

interface IState {
  anchorEl?: HTMLElement;
  openLocationForm: boolean;
}

class FloorplanItem extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
      openLocationForm: false,
    };

    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.handleMenuClose = this.handleMenuClose.bind(this);
    this.onConfigureFloorplan = this.onConfigureFloorplan.bind(this);
    this.onDeleteFloorplan = this.onDeleteFloorplan.bind(this);
    this.onEditFloorplan = this.onEditFloorplan.bind(this);
    this.onHideFloorplan = this.onHideFloorplan.bind(this);
    this.onShowFloorplan = this.onShowFloorplan.bind(this);
    this.onScanLocations = this.onScanLocations.bind(this);
    this.handleLocationFormClose = this.handleLocationFormClose.bind(this);
  }

  public handleMenuClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  public handleMenuClose() {
    this.setState({ anchorEl: undefined });
  }

  public onEditFloorplan() {
    const { handleEditFloorplan, floorplan } = this.props;

    this.setState({ anchorEl: undefined }, handleEditFloorplan(floorplan));
  }

  public onScanLocations() {
    this.setState({ anchorEl: undefined, openLocationForm: true });
  }

  public onHideFloorplan() {
    const { handleHideFloorplan, floorplan } = this.props;

    this.setState({ anchorEl: undefined }, handleHideFloorplan(floorplan));
  }

  public onShowFloorplan() {
    const { handleShowFloorplan, floorplan } = this.props;

    this.setState({ anchorEl: undefined }, handleShowFloorplan(floorplan));
  }

  public onConfigureFloorplan() {
    const { handleConfigureFloorplan, floorplan } = this.props;

    this.setState({ anchorEl: undefined }, handleConfigureFloorplan(floorplan));
  }

  public onDeleteFloorplan() {
    const { handleDeleteFloorplan, floorplan } = this.props;

    this.setState({ anchorEl: undefined }, handleDeleteFloorplan(floorplan));
  }

  public handleLocationFormClose() {
    this.setState({ openLocationForm: false });
  }

  public render() {
    const { anchorEl, openLocationForm } = this.state;
    const { floorplan, intl } = this.props;

    const messages = defineMessages({
      image: {
        defaultMessage: 'Floor plan',
        id: 'dashboard.settings.foorplan.image_alt',
      },
      more: {
        defaultMessage: 'More',
        id: 'dashboard.settings.foorplan.menu_aria_label',
      },
    });

    return (
      <Accordion key={floorplan.id}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          {floorplan.hidden === true ? (
            <VisibilityOffIcon style={{ opacity: 0.7, marginRight: '10px' }} />
          ) : null}
          <Typography>{floorplan.name}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <div style={{ textAlign: 'right' }}>
            <Button onClick={this.onConfigureFloorplan}>
              <SettingsIcon style={{ marginRight: '5px' }} />
              <FormattedMessage
                id="dashboard.settings.foorplan.changefpconfiguration"
                defaultMessage="Floor plan configuration"
              />
            </Button>
            <IconButton
              aria-label={intl.formatMessage(messages.more)}
              aria-owns={anchorEl ? 'long-menu' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenuClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              open={!!anchorEl}
              onClose={this.handleMenuClose}
            >
              <MenuItem onClick={this.onEditFloorplan}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.settings.foorplan.editfloorplan"
                      defaultMessage="Edit floor plan name"
                    />
                  }
                />
              </MenuItem>
              <MenuItem onClick={this.onScanLocations}>
                <ListItemIcon>
                  <AddLocationIcon />
                </ListItemIcon>
                <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.foorplan.scanItemLocations"
                        defaultMessage="Scan Item Locations"
                      />
                    }
                  />
              </MenuItem>
              {floorplan.hidden === true ? (
                <MenuItem onClick={this.onShowFloorplan}>
                  <ListItemIcon>
                    <VisibilityIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.foorplan.showfloorplan"
                        defaultMessage="Show floor plan"
                      />
                    }
                  />
                </MenuItem>
              ) : (
                <MenuItem onClick={this.onHideFloorplan}>
                  <ListItemIcon>
                    <VisibilityOffIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <FormattedMessage
                        id="dashboard.settings.foorplan.hidefloorplan"
                        defaultMessage="Hide floor plan"
                      />
                    }
                  />
                </MenuItem>
              )}
              <MenuItem onClick={this.onDeleteFloorplan}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <FormattedMessage
                      id="dashboard.settings.foorplan.deletefloorplan"
                      defaultMessage="Delete floo plan"
                    />
                  }
                />
              </MenuItem>
            </Menu>
          </div>
          <div
            style={{
              border: '1px solid #F1F1F1',
              clear: 'both',
              display: 'block',
              overflow: 'hidden',
              textAlign: 'center',
            }}
          >
            <div style={{ height: '350px', position: 'relative' }}>
              {floorplan.image ? (
                <img
                  style={{
                    bottom: '-9999px',
                    left: '-9999px',
                    margin: 'auto',
                    maxWidth: '100%',
                    position: 'absolute',
                    right: '-9999px',
                    top: '-9999px',
                  }}
                  src={floorplan.image}
                  alt={intl.formatMessage(messages.image)}
                />
              ) : null}
            </div>
          </div>
          {openLocationForm ? (
            <LocationForm
              onClose={this.handleLocationFormClose}
              floorplan={floorplan}
            />
          ) : null}
        </AccordionDetails>
      </Accordion>
    );
  }
}

export default injectIntl(FloorplanItem);
