import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import {
  IUpdateFloorplan,
  updateFloorplan,
} from '@actions/index';
import LocationFormDialog from './LocationForm';

const mapStateToProps = () => ({});
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateFloorplan: (properties: IUpdateFloorplan) =>
    dispatch(updateFloorplan.request(properties)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LocationFormDialog);
